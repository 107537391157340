<template>
    <div>
        <div class="d-flex justify-content-center align-items-center" v-if="showPreloader || fetchingNewData || waiting_filter" style="height: 90vh;">
            <b-spinner variant="primary" label="Text Centered" />
        </div>
        <div v-else>
            <table-unfound-address :unfoundAddresses="unfoundAddresses" @refresh="refresh" />
        </div>
        <filter-modalVue :fields="fields" @sendToParent="sendToParent"></filter-modalVue>
    </div>
</template>

<script>
import tableUnfoundAddress from "@/views/component/Table/tableUnfoundAddress.vue"
import filterModalVue from "@/views/component/FilterComponents/filterModal.vue"
export default {
    components: {
        filterModalVue,
        tableUnfoundAddress
    },
    data(){
        return {
            id: '',
            unfoundAddresses: [],
            showPreloader: false,
            fields: [ 
                { key: 'id', label: 'Id'},
                { key: 'name', label: 'Название'},
                { key: 'date_time', label: 'От/До'},
            ],
        }
    },
    mounted(){
        this.$store.commit('pageData/setdataCount', null)
        this.openFilter()
    },
    methods: {
        openFilter(){
            let allRoutes = this.$store.state.draggableTab.tabs
            let param = JSON.parse(localStorage.getItem(this.$route.name + 'data'))
            let arrayRoutes = []
            allRoutes.forEach(element => {
                arrayRoutes.push(element.path)
            });
            if (arrayRoutes.includes(this.$route.path)) {
                this.showPreloader = true
                this.$http
                    .get(`${this.$route.name}`, {params: param}).then(res => {
                        this.unfoundAddresses = res.data
                        this.$store.commit('pageData/setdataCount', this.unfoundAddresses.length)
                        this.showPreloader = false
                        this.$store.commit('REFRESH_DATA', false)
                    })
            }else if(arrayRoutes.includes(this.$route.path) == false){
                this.$bvModal.show(this.$route.name + 'filter')
            }
        },
        refresh(){
            let param = JSON.parse(localStorage.getItem(this.$route.name + 'data'))
            this.showPreloader = true
            this.$http
                .get('addresses/not-found', {params: param}).then(res => {
                    this.unfoundAddresses = res.data
                    this.$store.commit('pageData/setdataCount', this.unfoundAddresses.length)
                    this.showPreloader = false
                    this.$store.commit('REFRESH_DATA', false)
                })
        },
        sendToParent(tableData){
            this.unfoundAddresses = tableData
            this.$store.commit('pageData/setdataCount', this.unfoundAddresses.length)
        },
    },
    computed: {
        fetchingNewData(){
            return this.$store.state.refresh.fetchingNewData
        },
        waiting_filter() {
            return this.$store.state.refresh.waiting_filter
        }
    },
    watch: {
        fetchingNewData(val){
            if (val){
                this.$http
                .get(`${this.$route.name}`)
                .then(res =>{
                    this.unfoundAddresses = res.data
                    this.$store.commit('pageData/setdataCount', this.unfoundAddresses.length)
                    this.$store.commit('REFRESH_DATA', false)
                })
            }
        }
    }
}
</script>